/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family:
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1230px;
}
.footer {
  padding-top: 30px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  p {
    margin: 0 0 5px 0;
    font-size: 0.9rem;
  }
  ul {
    font-size: 0.9rem;
  }
  p.trustmark {
    display: none;
    margin: 0;
    #trustwaveSealImage {
      float: left;
      margin: 0 10px 30px 0;
    }
    &.in-column {
      display: block;
      #trustwaveSealImage {
        float: none;
        display: inline-block;
      }
    }
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 75%;
    max-width: 390px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      height: auto;
      width: auto;
      max-width: 100%;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      margin: 0 0 0 0;
      padding: 2px 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: #47b5e0;
      text-decoration: none;
      border-radius: 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: darken(#47b5e0, 7.5%);
      }
    }
    a.livechat {
      margin: 0 5px 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
  #search {
    float: right;
    clear: right;
    margin: 10px 0;
    input[type='search'] {
      padding-left: 35px;
      width: 170px;
      background: #f2f2f2 url(../images/search-icon.png) no-repeat 9px center;
      transition: all 0.5s cubic-bezier(0, 0.7, 0, 1);
      &:focus {
        width: 250px;
        background-color: #fff;
      }
      &::placeholder {
        color: #999 !important;
      }
    }
  }
}
input.addsearch {
  background: #f2f2f2 url(../images/search-icon.png) no-repeat 9px center;
  padding-left: 35px;
}
#nav {
  form {
    padding: 9px 20px;
    label {
      color: #fff;
    }
    input {
      margin-bottom: 5px;
      border: 0;
    }
  }
}

#banner-wrap {
  background-color: #137da0;
  &.gradient {
    background: #137da0 url(../images/banners/banner-bg-sm.jpg) no-repeat 50% 50% / cover;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.85);
    &.dd-sp {
      background: #137da0 url(../images/banners/banner-bg-sp-sm.jpg) no-repeat 50% 50% / cover;
    }
    &.dd-dr {
      background: #137da0 url(../images/banners/banner-bg-dr-sm.jpg) no-repeat 50% 50% / cover;
    }
    &.driver-ed {
      background: #137da0 url(../images/banners/banner-bg-driver-ed-sm.jpg) no-repeat 50% 10% / cover;
    }
    &.permit-test {
      background: #137da0 url(../images/banners/banner-bg-permit-test-sm.jpg) no-repeat 50% 50% / cover;
    }
    &.teen-driver-ed {
      background: #137da0 url(../images/banners/banner-bg-teen-sm.jpg) no-repeat 50% 10% / cover;
    }
    &.self-taught {
      background: #137da0 url(../images/banners/banner-bg-self-taught-sm.jpg) no-repeat 50% 30% / cover;
    }
  }
  &.overlay {
    position: relative;
    &:before {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.65;
      background-color: #000;
    }
    &.home {
      background: #137da0 url(../images/banners/banner-bg-homepage-sm.jpg) no-repeat 50% 50% / cover;
    }
    &.adult-driver {
      background: #137da0 url(../images/banners/banner-bg-adult-driver-sm.jpg) no-repeat 50% 50% / cover;
    }
    &.driver-ed {
      background: #137da0 url(../images/banners/banner-bg-driver-ed-sm.jpg) no-repeat 50% 50% / cover;
    }
    &.parent-taught {
      background: #137da0 url(../images/banners/banner-bg-parent-taught-sm.jpg) no-repeat 50% 50% / cover;
    }
    &.self-taught {
      background: #137da0 url(../images/banners/banner-bg-self-taught-sm.jpg) no-repeat 50% 50% / cover;
    }
    &.behind-the-wheel {
      background: #137da0 url(../images/banners/banner-bg-behind-the-wheel-sm.jpg) no-repeat 50% 50% / cover;
    }
    h1,
    h2,
    ul.check {
      text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.35);
    }
    .highlight {
      color: #f0dc9c;
    }
  }
  &.social {
    background: #7fc3cf;
    background-image: linear-gradient(#bcf5fe, #7fc3cf);
  }
  &.yellow {
    background: #e1bc37;
    background-image: linear-gradient(#f5d879, #e1bc37);
  }
  ul {
    font-weight: 600;
    color: #333;
    &.list-none {
      list-style-type: none;
      li {
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;
      }
    }
    &.check li:before {
      color: #dc5548;
    }
  }
  p.spanish {
    color: #333;
    font-weight: 600;
    a.spanish-link {
      color: #b10a0b;
    }
  }
}

#icons-wrap {
  background-color: $theme-primary;
  .icon-col {
    border-bottom: 1px dotted #ccc;
    &:last-child {
      border: 0;
    }
  }
  img {
    max-width: 35px;
    height: auto;
  }
  span {
    font-weight: 600;
    color: #fff;
  }
}

#insurance-wrap {
  background: $theme-primary;
  color: #fff;
}

.dropdown-list {
  width: 100%;
  max-width: 300px;
  span.dropdown.cta-dropdown {
    padding: 16px;
    padding-bottom: 14px;
    color: #fff;
    font-weight: 400;
    background: $cta-red;
    border: 0;
    width: 100%;
    height: auto;
    line-height: 1;
    font-size: 22px;
    text-align: left;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    & + .drop {
      top: 50px;
      font-weight: 600;
    }
    &:hover {
      background: darken($cta-red, 7);
    }
  }
}

.card-action {
  max-width: 480px;
  background: #fff url(../images/section_bg.jpg) no-repeat top right;
}

.card.course {
  background: #fff url(../images/section_bg.jpg) no-repeat scroll right top;
  box-shadow: 0 0 20px #e5f5ff inset;
}

.course-list {
  .course {
    border-bottom: 1px dotted #ccc;
  }
  h2 {
    color: $link-color;
    .age {
      color: #555;
      font-size: 14px;
    }
  }
  .cta-box {
    background: #2b445e;
    border-radius: 4px;
    .price {
      color: #fff;
    }
  }
}

aside {
  .card {
    background: #fff url(../images/section_bg.jpg) no-repeat top right;
    border-top: 3px solid darken($link-color, 7%);
    &.action {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
  }
}

#secure {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  span.green {
    color: #690;
    font-weight: 700;
    font-size: 24px;
  }
}

.accordion {
  &.accordion-custom {
    margin-bottom: 5px;
    border: 1px dotted #ccc;
    background: #fff;
    border-radius: 4px;
    .accordion-toggle {
      margin-left: 0;
      &:before {
        display: none;
      }
      &:after {
        color: #e94c48;
        content: '\e818';
        display: block;
        font-family: icons;
        font-size: 12px;
        font-weight: 400 !important;
        height: inherit;
        width: 12px;
        position: absolute;
        top: 15px;
        right: 0;
      }
    }
    &.is-open .accordion-toggle {
      &:after {
        content: '\e821';
      }
    }
  }
}

a.faqs {
  width: 100%;
  color: #333;
  background: #fff url(../images/section_bg.jpg) no-repeat 0 0;
  background-size: cover;
  border-radius: 5px;
  border: 1px solid #47b5e0;
  span.faq-wrap {
    display: block;
    padding-left: 35px;
  }
  span.faq-head {
    display: block;
    margin: 0 0 5px 0;
    font-weight: 800;
    font-size: 18px;
    color: #47b5e0;
  }
  &:before {
    color: #fd4041;
    content: '\e812';
    float: left;
    font-family: 'icons';
    font-size: 30px;
    font-weight: normal !important;
    height: inherit;
    vertical-align: middle;
    width: 35px;
    margin-top: 6px;
  }
  &:hover {
    text-decoration: none;
    border: 1px solid #2b445e;
  }
}

#reviews-wrap {
  border-bottom: 1px solid #e5ecf4;
  background: #fafafa;
  blockquote {
    color: #777;
  }
}

.sa_bgWhite .sa_review {
  border-radius: 3px !important;
  border: 1px solid #ccc !important;
  background: #f5f5f5 !important;
}

#footer-reviews {
  background-color: $theme-primary;
  color: #fff;
  .review {
    font-size: 1.2rem;
  }
}

blockquote {
  font-size: 1.45rem;
  line-height: 1.2;
  font-weight: 400;
  color: inherit;
  &.headline {
    font-size: 1.75rem;
  }
  p {
    font-family: Georgia, 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    font-style: italic;
    &:before,
    &:after {
      margin-right: 5px;
      display: inline-block;
      content: '\201C';
      color: inherit;
      opacity: 0.5;
      font-family: serif;
      font-size: 32px;
      font-weight: 700;
      vertical-align: middle;
      font-style: normal;
    }
    &:after {
      margin-left: 5px;
      content: '\201D';
    }
  }
  a {
    color: inherit;
  }
}

.border-top-dotted {
  border-top: 1px dotted #ccc;
}

/* TABS */
.nav-tabs {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: 0;
  .nav-item {
    margin-left: 1px;
    margin-right: 1px;
    font-weight: 600;
    .nav-link {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      color: $link-color;
      background-color: #efefef;
      border: 0;
      border-bottom: 1px solid #fff;
      font-size: 0.9rem;
      &.active {
        background-color: #fff;
      }
    }
  }
}
.tab-content {
  background-color: #fff;
  border-radius: 4px;
}

.btn-width {
  min-width: 220px;
}

/* COURSE WIZARD */
#course-wizard {
  border: 1px solid #0081ff;
  background: #fff;
  .question {
    display: none;
    .answers {
      margin: 15px 0 5px 0;
    }
    .exit-btn {
      width: 100%;
      max-width: 550px;
      height: auto;
      line-height: 1.4;
      font-size: 18px;
      font-weight: normal;
      font-family: 'Open Sans', Verdana, Tahoma, Arial, sans-serif;
      text-transform: none;
    }
    .register-btns {
      overflow: hidden;
      .col-half {
        float: none;
        width: 100%;
      }
    }
  }
  .nav-btn {
    display: none;
    overflow: hidden;
    text-align: right;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .nav-tabs {
    .nav-item {
      margin-left: 2px;
      margin-right: 2px;
      .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1rem;
      }
    }
  }
  #icons-wrap {
    .icon-col {
      border: 0;
    }
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    margin: 20px 0;
    img {
      display: block;
    }
  }
  #header-wrap {
    // border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
    }
  }
  #banner-wrap {
    ul.check,
    ul.list-none {
      font-size: 18px;
      &.normal {
        font-size: 1rem;
      }
    }
  }
}
.ul-custom {
  max-width: 500px;
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 50%;
  }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #banner-wrap {
    &.gradient {
      background: #137da0 url(../images/banners/banner-bg.jpg) no-repeat 50% 10% / cover;
      &.dd-sp {
        background: #137da0 url(../images/banners/banner-bg-sp.jpg) no-repeat 50% 10% / cover;
      }
      &.dd-dr {
        background: #137da0 url(../images/banners/banner-bg-dr.jpg) no-repeat 50% 10% / cover;
      }
      &.driver-ed {
        background: #137da0 url(../images/banners/banner-bg-driver-ed.jpg) no-repeat 50% 30% / cover;
      }
      &.permit-test {
        background: #137da0 url(../images/banners/banner-bg-permit-test.jpg) no-repeat 50% 50% / cover;
      }
      &.teen-driver-ed {
        background: #137da0 url(../images/banners/banner-bg-teen.jpg) no-repeat 50% 30% / cover;
      }
      &.self-taught {
        background: #137da0 url(../images/banners/banner-bg-self-taught.jpg) no-repeat 50% 30% / cover;
      }
    }
    &.overlay {
      &:before {
        opacity: 0.5;
      }
      &.home {
        background: #137da0 url(../images/banners/banner-bg-homepage.jpg) no-repeat 50% 50% / cover;
      }
      &.adult-driver {
        background: #137da0 url(../images/banners/banner-bg-adult-driver.jpg) no-repeat 50% 50% / cover;
      }
      &.driver-ed {
        background: #137da0 url(../images/banners/banner-bg-driver-ed.jpg) no-repeat 50% 50% / cover;
      }
      &.parent-taught {
        background: #137da0 url(../images/banners/banner-bg-parent-taught.jpg) no-repeat 50% 50% / cover;
      }
      &.self-taught {
        background: #137da0 url(../images/banners/banner-bg-self-taught.jpg) no-repeat 50% 50% / cover;
      }
      &.behind-the-wheel {
        background: #137da0 url(../images/banners/banner-bg-behind-the-wheel.jpg) no-repeat 50% 50% / cover;
      }
    }

    #banner {
      &.online {
        background: url(../images/adult.png) no-repeat -90px 100%;
      }
      &.defensive {
        background: url(../images/adult-2.png) no-repeat -60px 100%;
      }
    }
  }
  .course-list {
    .course {
      border-bottom: 0;
    }
  }
  #icons-wrap {
    .icon-col {
      border-bottom: 0;
    }
    img {
      max-width: 100%;
    }
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
